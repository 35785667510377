/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

.fs_13{
    font-size: 13px;
}
.pd-15{
    padding: 15px;
}
.mt-2{
    margin-top: 2rem;
}
.pd_10{
    padding: 10px;
}
.yellow_button{
    --background: #fcb816; 
    --color:#3d4749;
    font-weight: bold;
}
.red_button{
    --background: #E11218;
    --color:#FFF;
    font-weight: bold;
    //--color:#ffffff;
}
.black_text{
    color: #000;
}
.text-danger{
    color: #E11218;
}
.text-decoration-underline{
    text-decoration: underline;
}
.cursor-pointer{
    cursor: pointer;
}
.checkout-modal-custom.sc-ion-modal-md-h{
    --width: 85% !important;
    --height: 80% !important;
}
.w_60{
    width: 60%;
}

.popover-content ion-list ion-radio-group ion-item ion-label
{
    font-size: 14px !important;
    //color: green;
} 
.popover-content ion-list ion-label
{
    font-size: 14px !important;
    //color: red;
} 
.popover-content {
    width: auto !important;
    font-size: 14px !important;
    //margin-right: 150px !important;
    //--ion-text-color: #ff0000 !important;
}
.mapping-select .popover-content {
    top: 10% !important;
    // left: 33% !important;
    position: fixed !important;
}
.row-mapping-select .popover-content {
    top: 10% !important;
    left: 20% !important;
    width: 60% !important;
    position: fixed !important;
}
.ion-firefox .mapping-select .popover-content {
    width: auto !important;
}
 .ion-firefox .row-mapping-select .popover-content {
    width: 60% !important;
}
.my-custom-class-demo-page{
    --width: 1000px;
    --height: 600px;
}